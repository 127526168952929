:root {
	--drawerWidth: 230px;
	--primaryColor: #8534db;
}

html,
body,
div,
form,
p,
ul,
li,
span,
label,
img {
	margin: 0;
	padding: 0;
	outline: none;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	width: 100%;
	background-color: #fefefe;
}

body {
	position: relative;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	overflow: hidden;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-muted {
	color: #666;
}

.text-bold {
	font-weight: bold;
}


::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	-webkit-border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	background-color: #f3f3f3;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background: var(--primaryColor);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: var(--primaryColor);
}

.vjs-playback-rate {
	display: block !important;
}

#chatbot {
	ul {
		padding-left: 40px;
	}
}